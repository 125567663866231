import { replace } from "lodash";

class Util {
  constructor() { }

  //Validar se um email é válido
  validarEmail(email) {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  // validar CPF ou CNPJ
  validarCPFCNPJ(cpfCnpj) {
    if (!cpfCnpj) return false;
    cpfCnpj = cpfCnpj.replace(/[^\d]+/g, "");
    var validado = false;

    if (cpfCnpj.length == 14) {
      validado = this.validarCNPJ(cpfCnpj);
    }
    else if (cpfCnpj.length == 11) {
      validado = this.validarCPF(cpfCnpj);
    }

    return validado;
  }

  // Validação de CNPJ
  validarCNPJ(cnpj) {
    if (!cnpj) return false;
    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj == "") return false;

    if (cnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999"
    )
      return false;

    // Valida DVs
    var tamanho = cnpj.length - 2;
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;

    return true;
  }

  // Validar CPF
  validarCPF(strCPF) {
    if (!strCPF) return false;
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;

    for (var i = 1; i <= 9; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (i = 1; i <= 10; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  /**
   * Luiz Paulo - 13/11/2023
   * @param {*} formato 
   * Formato T - Americano
     Formato P - Brasileiro
   * @returns data atual 
   */
  diaAtual(formato) {
    const data_hoje = new Date();
    if (formato == 'T') {
      return data_hoje.getFullYear() + '-' + (data_hoje.getMonth() + 1) + '-' + data_hoje.getDate();
    } else {
      return data_hoje.getDate() + '/' + (data_hoje.getMonth() + 1) + '/' + data_hoje.getFullYear();
    }
  }

  /**
   * Luiz Paulo - 13/11/2023
   * @param {*} data_inicial 
   * @param {*} data_final 
   * @returns 
   */
  validarDataMenor(data_inicial, data_final) {

    var dataInicialSplit = data_inicial.split('-');
    var dataFinalSplit = data_final.split('-');
    var dataInicial = new Date(dataInicialSplit[0], dataInicialSplit[1] - 1, dataInicialSplit[2]);
    var dataFinal = new Date(dataFinalSplit[0], dataFinalSplit[1] - 1, dataFinalSplit[2]);
    if (!dataInicial || !dataFinal) {
      return false;
    }
    if (dataInicial.getTime() >= dataFinal.getTime()) {
      return true;
    } else {
      return false;
    }
  }

  alerta(type, msg) {
    console.log(type, msg);
  }

  getPromisseValue(objeto) {
    const dados = Promise.resolve(objeto);
    dados.then((retorno) => {
      return retorno;
    });
  }

  retirarMascara(parametro){
    parametro = replace(parametro, '/', '');
    parametro = replace(parametro, '-', '');
    parametro = replace(parametro, '.', '');
    parametro = replace(parametro, '.', '');

    return parametro;
  }  
}

export default new Util();

